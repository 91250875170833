<template>
  <Loader :value="promises.fetch" class="form" :style="style">
    <template v-if="config">
      <template v-if="!done">
        <header
          v-if="config.metadata"
          :class="['form__header', headerImage && 'form__header--image']"
          :style="
            headerImage && `background-image: linear-gradient(0, rgb(0 0 0 / 1), transparent 65%), url(${headerImage});`
          "
        >
          <div class="form__header-content">
            <h1 class="form__header-title">{{ config.metadata.title }}</h1>
            <h2 v-if="config.metadata.subtitle" class="form__header-subtitle">{{ config.metadata.subtitle }}</h2>
          </div>
        </header>

        <form class="form__content" @submit.prevent="submit">
          <h2 class="form__content-title">{{ config.label }}</h2>
          <CustomFields
            v-if="$path('metadata.schema.length', config)"
            :value="config.metadata.data || {}"
            :schema="config.metadata.schema"
            :validator="validator"
          />
          <template v-if="$path('schema.length', config)">
            <CustomFields v-model="data" :schema="config.schema" :validator="validator" :components="components" />
            <div class="form__content-actions">
              <ButtonComponent
                type="submit"
                :label="$path('metadata.labels.submit', config) || 'Fullfør'"
                :loading-label="$path('metadata.labels.loading', config) || 'Sender...'"
                :success-label="$path('metadata.labels.success', config) || 'Sendt'"
                :error-label="$path('metadata.labels.error', config) || 'Noe gikk galt'"
                :promise="promises.submit"
                :disabled="!validator.passes"
                :icon="$path('metadata.icons.submit', config) || ['fal', 'arrow-to-right']"
                theme="primary"
              />
            </div>
          </template>
        </form>
      </template>

      <div class="form-success-message" v-else>
        <FontAwesomeIcon class="form-success-message__icon" :icon="['fal', 'check-circle']" size="lg" />
        <h1 class="form-success-message__title">Skjemaet er sendt!</h1>
        <p class="form-success-message__body">
          Du vil snart bli tilsendt kvittering per epost. Du kan nå lukke dette viduet.
        </p>
        <ButtonComponent theme="primary" label="Lukk vinduet" :icon="['fal', 'arrow-to-right']" @click="closeWindow" />
      </div>
    </template>
  </Loader>
</template>

<script>
import Validator from '@/mixins/validator-v2'

import Card from '@kvass/card'
import CustomFields, { getValidatorConfig } from '@kvass/custom-fields'
import { ButtonComponent } from 'vue-elder-button'
import { LoaderComponent as Loader } from 'vue-elder-loader'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CustomComponents from '@/components/Form/CustomFields'
import { config } from '@fortawesome/fontawesome-svg-core'

export default {
  mixins: [
    Validator({
      rules: 'validatorConfig.rules',
      data: 'data',
      labels: 'validatorConfig.labels',
    }),
  ],
  props: {
    target: String,
    token: String,
  },
  watch: {
    id: {
      handler: 'fetch',
      immediate: true,
    },
  },
  data() {
    return {
      data: {},
      config: null,
      promises: {
        submit: null,
        fetch: null,
      },
      done: false,
      components: Object.values(CustomComponents),
    }
  },
  computed: {
    validatorConfig() {
      if (!this.config || !this.config.schema) return {}
      return getValidatorConfig(this.config.schema, this.data)
    },
    headerImage() {
      if (!this.config) return {}
      return this.config.metadata.cover ? this.config.metadata.cover.url : ''
    },
    style() {
      if (!this.config) return {}
      return this.config.metadata.style || {}
    },
  },
  methods: {
    submit() {
      const headers = { 'Content-Type': 'application/json' }
      if (this.token) headers.Authorization = `Bearer ${this.token}`

      this.promises.submit = fetch(this.target, {
        method: 'POST',
        headers,
        body: JSON.stringify(this.data),
      })
        .then(res => res.json())
        .then(data => {
          if (data.returnTo) return (location.href = data.returnTo)
          this.done = true
        })
    },
    fetch() {
      if (!this.target) return

      const headers = {}
      if (this.token) headers.Authorization = `Bearer ${this.token}`

      this.promise = fetch(this.target, {
        method: 'GET',
        headers,
      })
        .then(res => res.json())
        .then(data => {
          this.config = data
          this.data = this.config.data
        })
    },
    closeWindow() {
      window.close()
    },
  },
  components: {
    Card,
    CustomFields,
    ButtonComponent,
    Loader,
    FontAwesomeIcon,
  },
}
</script>

<style lang="scss">
.form {
  &__content {
    margin: 2rem auto;
    max-width: var(--form-width, #{$form-limit});
    padding-inline: $outerSpacing;

    &-actions {
      margin-top: 2rem;
    }
  }

  &__header {
    width: 100%;
    height: var(--header-height, 20vh);
    background-color: #f5f5f5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;

    &-content {
      max-width: var(--form-width, #{$form-limit});
      width: 100%;
      margin: 0 auto;
      padding: $outerSpacing;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &-title,
    &-subtitle {
      font-weight: bold;
      margin: 0;
    }

    &--image {
      height: var(--header-height, 40vh);
      background-color: #f5f5f5;

      .form__header-title {
        color: white;
        text-shadow: 0 2px 2px rgba(black, 0.5);
      }
    }
  }

  &-success-message {
    text-align: center;
    margin-block: 4rem;

    &__title,
    &__body {
      margin: 0.5rem;
    }

    .elder-button {
      margin-top: 2rem;
    }

    &__icon {
      width: 2rem !important;
      height: 2rem;
      margin-bottom: 1rem;
      color: var(--success);
    }
  }
}
</style>
